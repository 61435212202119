import { getScopedQuery } from './api'

/*
  Calculates the current scrollbar width.

  When the off-canvas menu was opened, the content behind
  it was shifted to the right by the width of the scrollbar.
  You have to hide the scrollbar, otherwise there would be 2 scrollbars
  next to each other, depending on the length and resolution of the off-canvas.
 */
function getScrollbarWidth() {
  const inner = document.createElement('p')
  inner.style.width = '100%'
  inner.style.height = '200px'

  const outer = document.createElement('div')
  outer.style.position = 'absolute'
  outer.style.top = '0px'
  outer.style.left = '0px'
  outer.style.visibility = 'hidden'
  outer.style.width = '200px'
  outer.style.height = '150px'
  outer.style.overflow = 'hidden'
  outer.appendChild(inner)

  document.body.appendChild(outer)
  const w1 = inner.offsetWidth
  outer.style.overflow = 'scroll'
  let w2 = inner.offsetWidth

  if (w1 == w2) {
    w2 = outer.clientWidth
  }

  document.body.removeChild(outer)

  return w1 - w2
}

/**
  Workaround to observe url changes, because there is no event fired by a browser
  if a URL is changed without a reload. The supposed 'popstate' event will be
  fired only on history.back() and history.forward().
  https://dirask.com/posts/JavaScript-on-location-changed-event-on-url-changed-event-DKeyZj
*/
function addLocationListener() {
  const pushState = history.pushState
  const replaceState = history.replaceState
  history.pushState = function(...args) {
    pushState.apply(history, args)
    window.dispatchEvent(new Event('pushstate'))
    window.dispatchEvent(new Event('locationchange'))
  }
  history.replaceState = function(...args) {
    replaceState.apply(history, args)
    window.dispatchEvent(new Event('replacestate'))
    window.dispatchEvent(new Event('locationchange'))
  }
  window.addEventListener('popstate', function() {
    window.dispatchEvent(new Event('locationchange'))
  })
}

/*
  It shows/hides the to-top-scroller button when user scrolls down
 */
function handleToTopButtonVisibility(toTopButton) {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    toTopButton.classList.remove('invisible')
  } else {
    toTopButton.classList.add('invisible')
  }
}

function normalizeQuotes(query) {
  return query.replace(/[\u2018\u2019\u201A\u201C\u201D\u201E\']/g, '"')
}

/// Variant of string.indexOf that returns all indices of a needle in a haystack string
function indicesOf(haystack, needle) {
  let lastIdx = -1
  const allIndices = []
  let idx
  while ((idx = haystack.indexOf(needle, lastIdx + 1)) >= 0) {
    allIndices.push(idx)
    lastIdx = idx
  }
  return allIndices
}

// Put code that should be run globally here
export default function init() {
  const offcanvas = document.getElementById('offcanvas')
  const body = document.querySelector('body')
  const scrollbarWidth = getScrollbarWidth()
  const openOffcanvas = document.getElementById('open-offcanvas')
  const closeOffcanvas = document.getElementById('close-offcanvas')

  function toggleOffCanvasMenu(show) {
    if (show) {
      offcanvas.classList.remove('hidden')
      openOffcanvas.setAttribute('aria-expanded', true)
      body.setAttribute('style', 'margin-right: ' + scrollbarWidth + 'px')
      body.classList.add('overflow-hidden')
      closeOffcanvas.focus()
    } else {
      offcanvas.classList.add('hidden')
      openOffcanvas.setAttribute('aria-expanded', false)
      body.removeAttribute('style')
      body.classList.remove('overflow-hidden')
      openOffcanvas.focus()
    }
  }

  // Offcanvas events
  if (offcanvas) {
    const mainMenu = document.getElementById('main-menu')
    mainMenu.addEventListener('keydown', evt => {
      if (evt.code === 'Escape') {
        toggleOffCanvasMenu(false)
      }
    })
    openOffcanvas.addEventListener('click', () => toggleOffCanvasMenu(true))
    closeOffcanvas.addEventListener('click', () => toggleOffCanvasMenu(false))
    offcanvas.addEventListener('click', () => toggleOffCanvasMenu(false))
    mainMenu.addEventListener('click', evt => evt.stopPropagation())
    document
      .getElementById('keyboard-close-offcanvas')
      .addEventListener('focusin', () => toggleOffCanvasMenu(false))
  }

  // Close the offcanvas-menu before leaving the page so users are not irritated
  window.addEventListener('beforeunload', () => toggleOffCanvasMenu(false))

  // Search events
  const searchI18n = {
    de: {
      metadata: 'Metadaten',
      ocr: 'Volltexten',
      placeholder: 'Suche in {fields}',
      and: 'und',
      badWildcard:
        'Für Volltextsuchen müssen trunkierte Terme außerhalb von Phrasen mindestens drei Zeichen haben',
    },
    en: {
      metadata: 'metadata',
      ocr: 'full texts',
      placeholder: 'Search {fields}',
      and: 'and',
      badWildcard:
        'For full text searches, terms with wildcards outside of phrases must have at least three characters',
    },
  }
  const currentLang = document.documentElement.lang

  // Toggling the mobile search menu
  const search = document.getElementById('search')
  const mobileSearch = document.getElementById('mobile-search')
  const toggleMobileSearch = document.getElementById('toggle-mobile-search')
  const hiddenSearchPath = document.querySelector(
    '#toggle-mobile-search path:last-of-type'
  )
  if (search || mobileSearch) {
    if (toggleMobileSearch) {
      toggleMobileSearch.addEventListener('click', function() {
        if (mobileSearch.classList.contains('hidden')) {
          mobileSearch.classList.remove('hidden')
          hiddenSearchPath.classList.remove('hidden')
          mobileSearch.querySelector('input').focus()
          toggleMobileSearch.setAttribute('aria-expanded', true)
        } else {
          mobileSearch.classList.add('hidden')
          hiddenSearchPath.classList.add('hidden')
          toggleMobileSearch.setAttribute('aria-expanded', false)
        }
      })
    }

    // The small rectangle that's protroding from the header and where the scope selection
    // and validation messages are shown
    const headerPopout = document.querySelector('.header-popout')

    // Ensuring field inputs only result in valid states and are consistent across views
    const queryInputs = document.querySelectorAll(
      'form[role=search] input[type=search]'
    )
    const metadataBoxes = document.querySelectorAll(
      'form[role=search] input[type=checkbox][name=search-metadata]'
    )
    const ocrBoxes = document.querySelectorAll(
      'form[role=search] input[type=checkbox][name=search-ocr]'
    )
    const keepFilterBoxes = document.querySelectorAll(
      'form[role=search] input[type=checkbox][name=keep-filters]'
    )

    // Set initial values for search inputs from url parameters
    const urlParams = new URLSearchParams(location.search)
    const params = Object.fromEntries(urlParams)
    if (params.query) {
      // eslint-disable-next-line prefer-const
      let handler = params.handler ?? 'simple-all'
      let query = params.query
      if (query.indexOf(':') >= 0) {
        const scoped = getScopedQuery(params.query)
        handler = `simple-${scoped[0]}`
        query = scoped[1]
      }
      if (query.startsWith('(') && query.endsWith(')')) {
        query = query.substring(1, query.length - 1)
      }
      queryInputs.forEach(inp => (inp.value = query))
      if (handler === 'simple-all') {
        ocrBoxes.forEach(inp => (inp.checked = true))
        metadataBoxes.forEach(inp => (inp.checked = true))
      } else if (handler === 'simple-fulltext') {
        ocrBoxes.forEach(inp => (inp.checked = true))
        metadataBoxes.forEach(inp => (inp.checked = false))
      } else if (handler === 'simple-metadata') {
        ocrBoxes.forEach(inp => (inp.checked = false))
        metadataBoxes.forEach(inp => (inp.checked = true))
      }
    }

    const enableKeepFilterBoxes = () => {
      keepFilterBoxes.forEach(inp => {
        inp.disabled = false
        inp.classList.remove('cursor-not-allowed')
        inp.parentElement.classList.remove('cursor-not-allowed', 'opacity-50')
        inp.parentElement.previousElementSibling.classList.remove('opacity-50')
        inp.classList.add('cursor-pointer')
      })
    }

    const disableKeepFilterBoxes = () => {
      keepFilterBoxes.forEach(inp => {
        inp.checked = false
        inp.disabled = true
        inp.classList.add('cursor-not-allowed')
        inp.parentElement.classList.add('cursor-not-allowed', 'opacity-50')
        inp.parentElement.previousElementSibling.classList.add('opacity-50')
        inp.classList.remove('cursor-pointer')
      })
    }

    // Custom event for watching url changes
    addLocationListener()
    window.addEventListener('locationchange', function() {
      const urlParams = new URLSearchParams(location.search)
      if (
        window.location.pathname.endsWith('/items') ||
        (window.location.pathname.endsWith('/search') &&
          urlParams.has('filter'))
      ) {
        enableKeepFilterBoxes()
      } else {
        disableKeepFilterBoxes()
      }
    })

    if (window.location.pathname.endsWith('/items')) {
      enableKeepFilterBoxes()
    }

    // Validate the query from the user
    function isQueryValid(query) {
      if (!query) {
        query = queryInputs[0].value
      }
      // An empty query or a query without a wildcard is always valid
      if (!query || query.indexOf('*') < 0) {
        return null
      }
      // We only forbid short wildcard queries when OCR text is targetted
      const hasOcr = ocrBoxes[0].checked
      if (!hasOcr) {
        return null
      }
      query = normalizeQuotes(query)
      // Matches any combination of two letters followed by an asterisk and not preceded by another letter
      const wildcardPat = /(^|[^\p{L}])\p{L}{1,2}\*/gu
      let hasBadWildcards = false
      let match
      while (!hasBadWildcards && (match = wildcardPat.exec(query)) != null) {
        const idx = match.index + 1
        // Check if the too short wildcard term is part of a phrase by counting
        // the number of quotes on the left and right of the term, if both sides
        // have an even number, it's not part of a phrase
        const quotesLeft = indicesOf(query.substring(0, idx), '"').length
        const quotesRight = indicesOf(query.substring(idx), '"').length
        if (quotesLeft % 2 == 0 && quotesRight % 2 == 0) {
          hasBadWildcards = true
        }
      }
      if (hasBadWildcards) {
        return 'badWildcard'
      }
      return null
    }

    /// Display a validation error in the search form
    function setQueryValidationError(errCode) {
      const errorMsg = searchI18n[currentLang][errCode]
      if (!queryInputs[0].checkValidity()) {
        // Already set, nothing to do
        return
      }
      queryInputs.forEach(inp => {
        const queryGroup = inp.parentNode
        const searchButton = queryGroup.querySelector('button')
        const invalidSymbol = queryGroup.querySelector('span.invalid-query')
        inp.setCustomValidity(errorMsg)
        queryGroup.classList.remove('border', 'border-theme-200')
        queryGroup.classList.add('border-2', 'border-red-400')
        searchButton.classList.add('hidden')
        searchButton.disabled = true
        invalidSymbol.classList.remove('hidden')
      })
      metadataBoxes.forEach(b => {
        const scopeContainer = b.parentNode.parentNode
        scopeContainer.classList.add('hidden')
      })
      document.querySelectorAll('.validation-error strong').forEach(elem => {
        elem.parentNode.classList.remove('hidden')
        elem.textContent = errorMsg
      })
      if (headerPopout != null) {
        // Make room for validation message in header
        headerPopout.classList.remove('h-6')
        headerPopout.classList.add('h-10')
      }
    }

    /// Clear a validation error from the search from
    function clearQueryValidationError() {
      if (queryInputs[0].checkValidity()) {
        // Already cleared, nothing to do
        return
      }
      queryInputs.forEach(inp => {
        const queryGroup = inp.parentNode
        const searchButton = queryGroup.querySelector('button')
        const invalidSymbol = queryGroup.querySelector('span.invalid-query')
        inp.setCustomValidity('')
        queryGroup.classList.add('border', 'border-theme-200')
        queryGroup.classList.remove('border-2', 'border-red-400')
        searchButton.classList.remove('hidden')
        searchButton.disabled = false
        invalidSymbol.classList.add('hidden')
      })
      metadataBoxes.forEach(b => {
        const scopeContainer = b.parentNode.parentNode
        scopeContainer.classList.remove('hidden')
      })
      document
        .querySelectorAll('.validation-error')
        .forEach(elem => elem.classList.add('hidden'))
      if (headerPopout != null) {
        headerPopout.classList.remove('h-10')
        headerPopout.classList.add('h-6')
      }
    }

    // Trigger pre-fetch of the search JS bundle when one of the search
    // inputs is first being interacted with
    queryInputs.forEach(inp => {
      inp.addEventListener('focus', () => {
        // Already preloaded? exit
        if (inp.dataset.searchPreloaded) {
          return
        }
        window
          .preloadSearch()
          .then(() =>
            queryInputs.forEach(i => (i.dataset.searchPreloaded = 'true'))
          )
      })
    })

    const updatePlaceholders = (hasMeta, hasOcr) => {
      // Update input placeholder
      let fieldDescription
      const strings = searchI18n[currentLang]
      if (hasOcr && hasMeta) {
        fieldDescription = `${strings.metadata} ${strings.and} ${strings.ocr}`
      } else if (hasOcr) {
        fieldDescription = strings.ocr
      } else if (hasMeta) {
        fieldDescription = strings.metadata
      }
      const placeholder = strings.placeholder.replace(
        '{fields}',
        fieldDescription
      )
      queryInputs.forEach(input => {
        input.placeholder = placeholder
        input.title = placeholder
      })
    }

    const onMetadataToggle = evt => {
      const hasOcr = ocrBoxes[0].checked
      const hasMeta = evt.target.checked

      // Disable the OCR checkboxes if changing them would lead to an inconsistent state
      if (!hasMeta && hasOcr) {
        ocrBoxes.forEach(checkbox => (checkbox.disabled = true))
      }
      // Re-enable the OCR checkboxes when changing them is safe again
      if (hasMeta) {
        ocrBoxes.forEach(checkbox => (checkbox.disabled = false))
      }

      // Make sure all metadata boxes (mobile + desktop) have a consistent state
      metadataBoxes.forEach(checkbox => (checkbox.checked = hasMeta))

      let errCode
      if ((errCode = isQueryValid()) != null) {
        setQueryValidationError(errCode)
      } else {
        clearQueryValidationError()
      }

      updatePlaceholders(hasMeta, hasOcr)
    }
    metadataBoxes.forEach(box =>
      box.addEventListener('click', onMetadataToggle)
    )

    const onOcrToggle = evt => {
      const hasOcr = evt.target.checked
      const hasMeta = metadataBoxes[0].checked

      // Disable the metadata checkboxes if changing them would lead to an inconsistent state
      if (!hasOcr && hasMeta) {
        metadataBoxes.forEach(checkbox => (checkbox.disabled = true))
      }
      // Re-enable the metadata checkboxes when changing them is safe again
      if (hasOcr) {
        metadataBoxes.forEach(checkbox => (checkbox.disabled = false))
      }

      // Make sure all OCR boxes (mobile + desktop) have a consistent state
      ocrBoxes.forEach(checkbox => (checkbox.checked = hasOcr))

      let errCode
      if ((errCode = isQueryValid()) != null) {
        setQueryValidationError(errCode)
      } else {
        clearQueryValidationError()
      }

      updatePlaceholders(hasMeta, hasOcr)
    }
    ocrBoxes.forEach(box => box.addEventListener('click', onOcrToggle))

    const onKeepFiltersToggle = evt => {
      // Make sure all keep-filter boxes (mobile + desktop) have a consistent state
      keepFilterBoxes.forEach(
        checkbox => (checkbox.checked = evt.target.checked)
      )
    }
    keepFilterBoxes.forEach(box =>
      box.addEventListener('click', onKeepFiltersToggle)
    )

    queryInputs.forEach(input =>
      input.addEventListener('input', evt => {
        // Sync search query inputs
        queryInputs.forEach(inp => (inp.value = evt.target.value))
        let errCode
        if ((errCode = isQueryValid()) != null) {
          setQueryValidationError(errCode)
        } else {
          clearQueryValidationError()
        }
      })
    )

    // Handling searches
    document.querySelectorAll('form[role=search]').forEach(form =>
      form.addEventListener('submit', evt => {
        evt.preventDefault()
        const hasOcr = ocrBoxes[0].checked
        const hasMeta = metadataBoxes[0].checked
        const keepFilter = keepFilterBoxes[0].checked

        // Normalize quotes
        let query = normalizeQuotes(queryInputs[0].value)

        if (query.indexOf(' ') >= 0) {
          query = '(' + query + ')'
        }

        let handler = 'simple-all'
        if (hasOcr && !hasMeta) {
          handler = 'simple-fulltext'
        } else if (!hasOcr && hasMeta) {
          handler = 'simple-metadata'
        }
        // Dynamically update search in-place if we're on the search page
        // or if we're on the collection items page and 'keep filters' is active.
        if (
          window.updateSearchQuery &&
          ((window.location.pathname.endsWith('/items') && keepFilter) ||
            window.location.pathname.endsWith('/search'))
        ) {
          window.updateSearchQuery(query, keepFilter, handler)
        } else {
          let targetUrl = `/${currentLang}/search?query=${encodeURIComponent(
            query
          )}`
          if (handler !== 'simple-all') {
            targetUrl = `${targetUrl}&handler=${handler}`
          }
          window.location.assign(targetUrl)
        }
      })
    )

    // To top scroller
    const toTopButton = document.getElementById('to-top-scroller')
    if (toTopButton) {
      window.onscroll = function() {
        if (document.body.scrollHeight > window.innerHeight * 2) {
          handleToTopButtonVisibility(toTopButton)
        }
      }
      toTopButton.addEventListener('click', function() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      })
    }
  }

  // Allow-Youtube-Handling
  for (const el of document.querySelectorAll('.accept-iframe')) {
    el.addEventListener('click', () => {
      const placeholderElement = el.parentNode
      const dataAttributes = placeholderElement.dataset
      const iframeElement = document.createElement('iframe')
      for (const key in dataAttributes) {
        iframeElement.setAttribute(key, dataAttributes[key])
      }
      placeholderElement.replaceWith(iframeElement)
    })
  }

  // handle button back to collection list
  const toCollectionListButton = document.querySelector('.to-collection-list')
  if (toCollectionListButton) {
    toCollectionListButton.addEventListener('click', evt => {
      window.location.href = evt.currentTarget.dataset.href
    })
  }
}
