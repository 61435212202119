import initGlobal from './global.js'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import polyfills from './polyfills.js'

/** Preload the search JavaScript bundles */
window.preloadSearch = function() {
  return import('./search')
}

/** Render a map */
window.renderMap = function(options) {
  import('./map').then(({ default: renderMap }) => renderMap(options))
}

/** Render the search/search-result interface into the DOM node with the given id*/
window.renderSearch = function(targetId, context, fixedFilters, updateTitle) {
  // Update language-switcher link when the search changes the current URL
  const langLink = document.querySelector('#main-menu a[lang]')
  const targetLang = langLink?.lang
  const linkPat = new RegExp(`/${targetLang === 'en' ? 'de' : 'en'}/`)
  const urlCallback = newUrl => {
    langLink.href = newUrl.replace(linkPat, `/${targetLang}/`)
  }
  return import('./search').then(({ default: renderSearch }) =>
    renderSearch(targetId, context, fixedFilters, urlCallback, updateTitle)
  )
}

/** Render a slider with the given options (see [1])
 *
 * [1] https://github.com/ganlanyuan/tiny-slider#options) */
window.renderSlider = function(options) {
  import('tiny-slider/src/tiny-slider.module').then(({ tns }) => {
    tns(options)
  })
}

// Assignments to the window object are needed since parcel v2 currently does
// not support exporting to the global namespace.
// FIXME: Remove once parcel-bundler/parcel#4476 is resolved
/** Render a Mirador viewer displaying a manifest to the DOM node with the given id */
window.renderViewer = function(language, manifestUrl, targetId) {
  import('./viewer').then(({ default: renderViewer }) =>
    renderViewer(language, manifestUrl, targetId)
  )
}

// Execute the global initialization code once the DOM content has finished loading
document.addEventListener('DOMContentLoaded', initGlobal)
