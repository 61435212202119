import { Translations, TranslationMap } from './helpers'

const bracketQueryPat = new RegExp(/^\((.+)\)$/gu)
const phraseQueryPat = new RegExp(/^"([^"]+)"$/gu)
export const NUM_FACETS_PER_PAGE = 10
const OCRSNIPPET_CONTEXT_SIZE = 1

export const availableFacets = [
  'date_facet',
  'language_facet',
  'pers_inst_facet',
  'place_publ_facet',
  'type_manufact',
  'type_media_facet',
  'type_content',
  'subject_facet',
  'shelfgroup_not_facet',
  'collections_facet',
  'provenance_facet',
  'features',
]

export enum SortField {
  None = 'NONE',
  Relevancy = 'relevancy',
  PublicationDate = 'date',
  Title = 'title',
  Author = 'author',
  Signatur = 'shelfNo',
  ScanDate = 'scanDate',
}

export enum SortOrder {
  Ascending = 'asc',
  Descending = 'desc',
}

export enum FilterOp {
  Include = '+',
  Exclude = '-',
  Range = 'Range',
}

export interface Filter {
  field: string
  value: string | [string, string]
  op?: FilterOp
}

export type SearchHandler =
  | 'simple-all'
  | 'simple-metadata'
  | 'simple-fulltext'
  | 'advanced'

/** Parameters for the search API */
export interface SearchParams {
  query?: string
  sortField?: SortField
  sortOrder?: SortOrder
  startPage?: number
  pageSize?: number
  handler?: SearchHandler
  filters?: Filter[]
  explainScores?: boolean
}

/** Faceting information for a single term, with optional translations for the value */
export interface TermCount {
  value: string
  count: number
  translations?: Translations
}

/** Faceting information for a given field */
export interface FacetInfo {
  filterField: string
  numTotal?: number
  counts: TermCount[]
}

/* API response types */
export interface Page {
  id: string
  width: number
  height: number
}

export interface Region {
  x: number
  y: number
  width: number
  height: number
  pageIdx?: number
  text?: string
}

export interface HighlightSpan {
  highlights: Highlight[]
  text: string
}

export interface Highlight extends Region {
  text: string
  parentRegionIdx: number
}

export interface OcrSnippet {
  text: string
  pages: Page[]
  regions: Region[]
  highlightSpans: HighlightSpan[]
}

export interface Document {
  id: string
  doi: string
  parentId?: string
  shelfNos: string[]
  provenance: string
  authors?: string[]
  publicationDate?: string
  publishers?: string[]
  publicationPlaces?: string[]
  title?: string
  previewPage: string
  mediaTypes?: string[]
  entityType: string
  lastModified: Date
  totalOcrSnippets?: number
  ocrSnippets?: OcrSnippet[]
  scoreExplanation?: ScoreNode
  specialType?: string
  manufacturingType?: string
  contentType?: string
  iiifAvailable: boolean
}

export interface ScoreNode {
  op: string // TODO: Make this a union of all possible values
  result: number
  description?: string
  args?: ScoreNode[]
}

export interface FieldWeight extends ScoreNode {
  term: string
  field: string
}

export interface InverseDocFreq extends ScoreNode {
  docCount: number
  docFreq: number
}

export interface NormalizedTermFreq extends ScoreNode {
  freq: number
  k1: number
  b: number
  avgFieldLength: number
  fieldLength: number
}

export interface SearchResults {
  numTotal: number
  params: SearchParams
  docs: Document[]
  facets: {
    [field: string]: FacetInfo
  }
  filterTranslations: {
    [field: string]: TranslationMap
  }
}

export interface ParentWork {
  bvId: string
  title: string
  numContainedWorks: number
}

export interface Entity {
  name: string
  nameOriginalScript?: string
  lifeDates?: string
  gndId?: string
  roles?: Translations[]
}

export interface Collection {
  id: number
  labels: Translations
}

export interface Identifier {
  name: string
  value: string
  url?: string
}

export interface Relations {
  object?: number
  whole?: number
  manifestation?: number
  work?: number
  parentWorks?: ParentWork[]
  watermark?: number
  microscope?: number
  specs?: number
  singles?: number
  boundWith?: number
  ados?: number
  otherParts?: number
  originId?: string
}

export interface Metadata {
  id: string
  title: string
  byline?: string
  creators?: Entity[]
  workTitle?: string
  workGndId?: string
  subjects?: string[]
  contributors?: Entity[]
  superWorks?: string[]
  publishedBy?: string[]
  publishedDate?: string
  extent?: string
  dimensions?: string
  scale?: string
  languages?: Translations[]
  notes?: string[]
  description?: Translations
  contentTypes?: Translations[]
  manufacturingTypes?: Translations[]
  mediaTypes?: Translations[]
  manifestationIdentifiers?: Identifier[]
  provenance?: string
  shelfNumber?: string
  shelfGroups: string[]
  urn?: string
  numScans?: number
  scanDate: string
  collections?: Collection[]
  relations?: Relations
  license: string
  idB3Kat?: string
  iiifAvailable: boolean
  specialType?: string
  lsId?: string
}

export type LocalizedText = Record<string, string>

export interface Agent {
  label: LocalizedText
}

export interface CreationInfo {
  date?: string
  creator?: Agent
}

export interface MetadataIdentifier {
  id: string
  namespace: string
}

export interface MetadataTag {
  value: string
}

export interface Holder {
  label: LocalizedText
}

export interface Item {
  identifiers: MetadataIdentifier[]
  holders: Holder[]
  tags: MetadataTag[]
}

export interface Content {
  type: string
  content?: Content[]
  text?: string
}

export interface RenderingResource {
  uuid: string
  label: Record<string, string>
  description: Record<string, Content>
  uri: string
}

export interface DigitalObject {
  identifiers: MetadataIdentifier[]
  numberOfBinaryResources?: number
  creationInfo?: CreationInfo
  item?: Item
  customAttributes?: Record<string, string>
  renderingResources?: RenderingResource[]
}

export type Modifier = 'should' | 'must' | 'must_not'
export type ModifierShort = '' | '+' | '-'

export interface FieldQuery {
  fieldName: string
  query: string
  modifier: Modifier
}

export const modifierShort2Long = {
  '': 'should',
  '+': 'must',
  '-': 'must_not',
}

export const modifierLong2Short = Object.fromEntries(
  Object.entries(modifierShort2Long).map(([k, v]) => [v, k])
)

const fieldQueryPat = new RegExp(
  /([+\-])?([a-za-z0-9_]+)\s*:\s*((?:\(.+\))|(?:".+?")|(?:[^\s]+))/gu
)

/** Parse a query into individual field queries */
export function parseQuery(query: string): { [fieldName: string]: FieldQuery } {
  const out: { [fieldName: string]: FieldQuery } = {}
  // FIXME: We should really be using String.matchAll here, but the polyfills is broken
  //        for iOS Safari in my tests, so we'll just go with the old-fashioned way.
  //        I really don't have the nerve to debug parcel/babel/core-js issues at the moment.
  let match: RegExpExecArray | null
  while ((match = fieldQueryPat.exec(query)) !== null) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, modifier, fieldName, query] = match
    out[fieldName] = {
      fieldName,
      query,
      // FIXME: Get rid of the casting?
      modifier: modifierShort2Long[
        (modifier ?? '') as ModifierShort
      ] as Modifier,
    }
  }
  return out
}

/** Given a list of field queries, compile a full query for the backend */
export function compileQuery(fieldQueries: FieldQuery[]): string {
  return fieldQueries
    .map(fq => `${modifierLong2Short[fq.modifier]}${fq.fieldName}:${fq.query}`)
    .join(' ')
}

/// Wrap the field query for consumption by the query parser
export function wrapQuery(query: string): string {
  if (query.indexOf(' ') > -1 && !phraseQueryPat.test(query)) {
    return `(${query})`
  }
  return query
}

/// Unwrap the field query for editing by the user
export function unwrapQuery(query: string): string {
  const match = bracketQueryPat.exec(query)
  if (match) {
    return match[1]
  }
  return query
}

/// Returns a [scope, query] tuple from a query string like "scope:query"
export function getScopedQuery(queryStr: string | undefined): [string, string] {
  const fieldQueries = parseQuery(queryStr || '')
  if (fieldQueries.all !== undefined) {
    return ['all', fieldQueries.all.query]
  } else if (fieldQueries.fulltext !== undefined) {
    return ['fulltext', fieldQueries.fulltext.query]
  } else if (fieldQueries.metadata !== undefined) {
    return ['metadata', fieldQueries.metadata.query]
  } else {
    return ['all', queryStr ?? '']
  }
}

/** Given a filter, compile it into a form that is accepted by the backend */
export function compileFilter({ field, value, op }: Filter): string {
  if (Array.isArray(value)) {
    // Date range filter, start and end are both inclusive
    const [start, end] = value
    return `${field}:[${start} TO ${end}]`
  } else {
    // Simple including or excluding term filter
    const modifier = op === FilterOp.Exclude ? '-' : ''
    if (!value.startsWith('"') && !value.endsWith('"')) {
      value = `"${value}"`
    }
    return `${field}:${modifier}${value}`
  }
}

/** Perform a search via the search API */
export async function performSearch(
  {
    query,
    sortField,
    sortOrder,
    startPage,
    pageSize,
    handler,
    filters,
    explainScores,
  }: SearchParams,
  fixedFilters?: Filter[]
): Promise<SearchResults> {
  const url = new URL('/api/search', window.location.origin)
  url.searchParams.set('query', query ?? '')
  url.searchParams.set('handler', handler ?? 'simple-all')
  url.searchParams.set('ocrContext', OCRSNIPPET_CONTEXT_SIZE.toString())

  if (sortField && sortField !== SortField.Relevancy && sortOrder) {
    url.searchParams.set('sortField', sortField)
    url.searchParams.set('sortOrder', sortOrder)
  }

  if (startPage != null) {
    // We use 1-based indices in the UI, but the API expectes 0-based,
    // so we have to decrement the index before passing it to the API
    url.searchParams.set('startPage', (startPage - 1).toString())
  }

  if (pageSize != null) {
    url.searchParams.set('pageSize', pageSize.toString())
  }

  if (explainScores) {
    url.searchParams.set('explainScores', '')
  }

  const allFilters = [...(filters ?? []), ...(fixedFilters ?? [])]
  if (allFilters.length > 0) {
    allFilters.forEach(filter => {
      url.searchParams.append('filter', compileFilter(filter))
    })
  }

  const requestUrl = url.toString()
  const resp = await fetch(requestUrl)
  if (!resp.ok) {
    throw new Error(
      `Search failed with code ${resp.status}: ${resp.statusText}`
    )
  }
  /* Because the enum is just some typescript fun it actually is a string.
     Since the string that comes from the server is in capital letters we must
     fix it here to meet our lowercase style.
  */
  const createEnumFromJson = function(key: string, value: any): any {
    if (key == 'sortOrder' && value) {
      return (value as string).toLowerCase()
    }
    return value
  }
  return await resp.text().then(text => JSON.parse(text, createEnumFromJson))
}

/** Given the encoded form of a filter, parse it into a Filter data structure */
export function parseFilter(filter: string): Filter {
  const [field, query] = filter.split(':')
  if (
    query.startsWith('[') &&
    query.endsWith(']') &&
    query.indexOf(' TO ') > 0
  ) {
    const [start, end] = query.substring(1, query.length - 1).split(' TO ')
    return { field, value: [start, end], op: FilterOp.Range }
  } else {
    const op = query[0] === '-' ? FilterOp.Exclude : FilterOp.Include
    return {
      field,
      value: query.replace(/^[+-]?"?(.+?)"?$/, '$1'),
      op,
    }
  }
}

/** Fetch additional snippets for a given OCR query and document */
export async function fetchSnippets(
  query: string,
  docId: string,
  count = 5
): Promise<OcrSnippet[]> {
  const url = new URL('/api/snippets/' + docId, window.location.origin)
  url.searchParams.set('q', query)
  url.searchParams.set('count', count.toString())
  url.searchParams.set('context', OCRSNIPPET_CONTEXT_SIZE.toString())
  const resp = await fetch(url.toString())
  return await resp.json()
}

/** Filter the facet values for a given field and query.
 *
 * Supports pagination and a simple lexical 'contains' query.
 */
export async function filterFacet(
  { handler, query, filters }: SearchParams,
  field: string,
  contains: string,
  page: number
): Promise<TermCount[]> {
  const url = new URL('/api/search/facet', window.location.origin)
  url.searchParams.set('query', query ?? '')
  url.searchParams.set('handler', handler ?? 'simple-all')
  url.searchParams.set('field', field)
  if (contains.length > 0) {
    url.searchParams.set('contains', contains)
  }
  url.searchParams.set('offset', (page * NUM_FACETS_PER_PAGE).toString())
  url.searchParams.set('count', NUM_FACETS_PER_PAGE.toString())

  if (filters) {
    filters.forEach(filter => {
      url.searchParams.append('filter', compileFilter(filter))
    })
  }

  const requestUrl = url.toString()
  const resp = await fetch(requestUrl)
  return await resp.json()
}

/** Fetch the full set of metadata for a given MDZ identifier */
export async function fetchMetadata(id: string): Promise<Metadata> {
  const resp = await fetch(`/api/metadata/${id}`)
  return await resp.json()
}

/** Fetch the fully filled DigitalObject with all details for a given MDZ identifier */
export async function fetchDetails(id: string): Promise<DigitalObject | null> {
  try {
    const resp = await fetch(`/api/details/${id}`)
    if (!resp.ok) {
      return null
    }
    return await resp.json()
  } catch (err) {
    return null
  }
}

/** Indicate to the backend that a page view in the viewer has happened.  */
export async function trackViewerPageView(
  volumeId: string,
  pageNum: string
): Promise<void> {
  try {
    await fetch(`/api/statistics/pageview?vol=${volumeId}&p=${pageNum}`, {
      method: 'POST',
    })
  } catch {
    // NOP
  }
  return
}
